import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [LoadingComponent, HeaderComponent],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [LoadingComponent, HeaderComponent],
})
export class CommonComponentsModule {}
